import React from "react";
import strings from 'strings.json';

const Bekreftelse = () => {

    return (
        <div>
            <h1>{strings.bekreftelse}</h1>
        </div>
    );
};

export default Bekreftelse;